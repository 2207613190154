/*
1. BASIC
2. HELPER
3. BOOTSTRAP RESET
4. NAVBAR
5. HOME
6. SERVICES
7. ABOUT US
8. WEBSITE DESCRIPTION
9. PRICING BOX
10. TEAM
11. OUR PROCESS
12. PEOPLE SAYS
13. GET STARTED
14. BLOG
15. CONTACT
16. FOOTER
17. RESPONSIVE
*/
@font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/montserrat/Montserrat-Regular.ttf');
}


/* ==========
   1.BASIC
   ========== */

body {
    font-family: 'Montserrat-Regular';
    /*font-family: 'Rubik', sans-serif;*/
    overflow-x: hidden !important;
    position: relative;
}

a,
a:hover,
a:focus,
button {
    text-decoration: none !important;
    outline: none !important;
}

p {
    font-size: 14.4px;
    line-height: 24px;
}

.h1,
h1 {
    font-size: 36px;
}

.h2,
h2 {
    font-size: 30px;
}

.h3,
h3 {
    font-size: 24px;
}

.h4,
h4 {
    font-size: 18px;
}

.h5,
h5 {
    font-size: 14px;
}

.h6,
h6 {
    font-size: 12px;
}

b {
    font-weight: 500;
}

::-moz-selection {
    background-color: #2e3138;
    color: #fff;
}

::selection {
    background-color: #2e3138;
    color: #fff;
}



/*====================
  2.HELPER
  ===================*/

.margin-t-20 {
    margin-top: 20px;
}

.margin-t-30 {
    margin-top: 30px;
}

.margin-t-50 {
    margin-top: 50px;
}

.margin-b-0 {
    margin-bottom: 0px;
}

.padding-t-15 {
    padding-top: 15px;
}

.padding-t-30 {
    padding-top: 30px;
}

.padding-b-10 {
    padding-bottom: 10px;
}

.height-100vh {
    height: 100vh;
}

.vertical-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.bg-overlay {
    background: #deeaf6;
    /* fallback for old browsers */
    /* background: linear-gradient(to right, #363795, #005C97); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.80;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
}

.bg-overlay-light {
    background-color: rgba(0, 0, 0, 0.5);
}

.section {
    padding-top: 40px;
    padding-bottom: 80px;
    position: relative;
}

.section-sm {
    padding: 50px 0;
}

.section-lg {
    padding: 100px 0;
}

.section-title {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0px;
    padding-bottom: 1rem;
    line-height: 1;
    font-size: 1.75rem;
}

.section-title-border {
    width: 50px;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
}

.section-subtitle {
    letter-spacing: 0.02em;
}

.svg-pattern {
    width: 100%;
    position: relative;
}

iframe {
    max-width: 100%;
}


.hover-effect {
    transition: all .3s ease-in-out;
}

.hover-effect:hover {
    transform: translateY(-1rem);
}

/*----BACKGROUND COLOR-----*/



.bg-gray {
    background-color: #f7f7f7;
}

.bg-white {
    background-color: #fff;
}

.bg-dark {
    background-color: #393f4f !important;
}

.bg-dark-gray {
    background-color: #9da4ab !important;
}

.bg-white-gray {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(157, 164, 171, 1) 50%);
}

.bg-gray-white {
    background: linear-gradient(90deg, rgba(157, 164, 171, 1) 27%, rgba(255, 255, 255, 1) 27%);
}

.bg-light {
    background-color: #fafbfe !important;
}

.bg-blue {
    background-color: #ebf2ff !important;
}

.bg-clear-blue {
    background-color: #04a4df !important;
}

.bg-dark-blue {
    background-color: #7b98c3 !important;
}

.bg-yellow {
    background-color: #fbcb24 !important;
}




/*=====================
  3. BOOTSTRAP RESET
  ======================*/


/*--Text color--- */

.text-muted {
    color: #858d96 !important;
}

.text-white {
    color: #fff !important;
}

.text-gray {
    color: #777 !important;
}

.text-dark {
    color: #212121 !important;
}

.text-red {
    color: #c01919 !important;
}


/*====Buttons=======*/

.btn {
    padding: 10px 22px;
    transition: all 0.5s;
    box-shadow: none;
}

.btn:hover {
    transform: translateY(-5px);
}

.btn-rounded {
    border-radius: 30px;
}

.btn-custom {
    border: 1px solid;
    background-color: #ececec !important;
    border-color: transparent;
    color: #7b9ed0;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: bold;
}

.btn-custom-general {
    border: 1px solid;
    background-color: #ececec !important;
    border-color: transparent;
    color: #7b9ed0;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: bold;
}

.btn-custom-start-today-blue {
    border: 1px solid;
    background-color: #04a4df !important;
    border-color: transparent;
    color: white;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: bold;
    /* -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27); */
}

.btn-custom-start-today-blue:hover,
.btn-custom-start-today-blue:focus,
.btn-custom-start-today-blue:active,
.btn-custom-start-today-blue.active,
.btn-custom-start-today-blue.focus,
.btn-custom-start-today-blue:active,
.btn-custom-start-today-blue:focus,
.btn-custom-start-today-blue:focus,
.btn-custom-start-today-blue:active {
    color: white;
    background-color: #088bbb !important;
    border-color: transparent;
}

.btn-custom-start-today {
    border: 1px solid;
    background-color: #fbcb24 !important;
    border-color: transparent;
    color: #656c70;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: bold;
    /* -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27); */
}

.btn-custom-start-today:hover,
.btn-custom-start-today:focus,
.btn-custom-start-today:active,
.btn-custom-start-today.active,
.btn-custom-start-today.focus,
.btn-custom-start-today:active,
.btn-custom-start-today:focus,
.btn-custom-start-today:focus,
.btn-custom-start-today:active {
    color: white;
    background-color: #ffc800 !important;
    border-color: transparent;
}

.btn-custom-navbar {
    border: 1px solid;
    background-color: #fbcb24 !important;
    border-color: transparent !important;
    color: #656c70;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: bold;
}

/* .is-sticky .btn-custom-questions {
    color: white;
} */
.btn-custom-questions {
    background-color: transparent !important;
    color: #656c70;
    border: none;
    font-weight: bold;
}

.btn-custom-questions:hover {
    color: rgb(27, 175, 228);
}

.custom-form-input {
    background-color: #f5f4fc;
    font-weight: bold;
}

.custom-form-label {
    color: #160e71;
    font-weight: bold;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open>.dropdown-toggle.btn-custom {
    border: 1px solid !important;
    color: #000 !important;
}

.btn-bg-white {
    background-color: #fff !important;
    transition: all 0.3s;
    border: 2px solid #fff;
    color: #000 !important;
    font-size: 14px;
}

.read-btn {
    font-size: 15px;
}

.read-btn:hover {
    color: #212529 !important;
}


/*======================
  4.NAVBAR
  ======================*/
.color-blue {
    display: none;
}

.custom-hr-curiosity {
    color: white;
    width: 100%;
}

.z-index-0 {
    z-index: 0;
}

.color-grey {
    color: #656c70;
}

.navbar-custom {
    background-color: rgba(255, 255, 255, 1);
    padding: 20px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
    position: absolute;
    width: 100%;
}

.navbar-nav {
    margin-left: 50px;
    border-bottom: none;
}

.navbar-custom .navbar-brand {
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 700;
    letter-spacing: 2px;
    height: auto;
}

/* .navbar-custom .navbar-nav li a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    background-color: transparent !important;
    padding: 15px 0;
    margin: 0 10px;
    transition: all 0.4s;
}

.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
    color: rgb(27, 175, 228);
}

.navbar-custom .navbar-nav li.active a {
    color: rgb(27, 175, 228);
} */

/* .is-sticky .navbar-custom,
.is-sticky .row,
.is-sticky .a {
    margin-top: 0px;
    padding: 10px 0px;
    background-color: #808589;
    color: white !important;
} */


.custom-navlink {
    margin: 0 10px;
    color: #656c70 !important;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 2px solid rgba(0, 0, 0, .1);
    padding-bottom: 2px !important;
    transition: all 0.4s;
}

.nav-active {
    color: rgb(27, 175, 228) !important;
    background-color: #e1ecf3 !important;
    border-radius: 1rem;
}

/* .navbar-toggle {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #ffffff;
} */

.navbar-custom .btn-custom {
    margin-top: 4px;
    margin-bottom: 4px;
}



.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent;
    border-color: #337ab7;
}


/*---Navbar-White--*/

.navbar-custom.navbar-white {
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
}

.navbar-custom.navbar-white .navbar-nav li a,
.navbar-custom.navbar-white .navbar-brand,
.navbar-white .navbar-toggle {
    color: #393f4f !important;
}

.tingo-icon {
    background: url('../images/tingo1.jpg');
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}

.imgTranslator {
    padding: 10px;
}

/*=======================================
          5.HOME 
  =====================================*/

.bg-home {
    background: url("../images/backgrounds/tingo-home-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-teachers-home {
    background: url("../images/backgrounds/tingo-teachers-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-germany-home {
    background: url("../images/backgrounds/tingo-germany-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-news-home {
    background: url("../images/backgrounds/tingo-news-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-companies-home {
    background: url("../images/backgrounds/tingo-companies-bg-alt.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-fix {
    height: 933px;
}

.with-background {
    padding: 3rem;
    background-color: rgb(255, 255, 255, 0.95);
    border-radius: 2rem;
    box-shadow: 0px 20px 26px -7px rgba(0, 0, 0, 0.75);
}

.bg-form {
    background: url("../images/bg-home1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.home-title {
    color: #88949e;
    text-transform: uppercase;
    justify-content: left;
    font-size: 46px;
    line-height: 64px;
    font-weight: 300;
}

.home-small-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 7px;
}

.display-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.display-table {
    display: table;
    width: 100%;
    height: 100%;
}

.home-up {
    z-index: 10;
    position: relative;
}

.play-btn {
    border: 2px solid;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: block;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
}

.play-shadow {
    border-radius: 50%;
    border: 1px solid transparent;
    width: 82px;
    height: 82px;
    margin: 20px auto 0px auto;
}

.home-desc {
    color: #88949e;
    font-size: 20px;
    max-width: 600px;
}

.property-container {
    height: 100px;
    width: 100px;
    border-radius: 1rem;
    padding: 1rem;
    border: solid 2px #c0cfe4;
    margin-bottom: 15px;
    text-align: center;
}

.custom-germany-property-container {
    background-color: white;
    padding: 2rem;
    /* text-align: center; */
    /* justify-content: center; */
    /* margin: 10px 10px; */
    border-radius: 0.5rem;
    /* min-height: 144px; */
    /* -webkit-box-shadow: 5px 4px 2px 3px rgba(202, 187, 102, 0.5);
-moz-box-shadow: 5px 4px 2px 3px rgba(202, 187, 102, 0.5);
box-shadow: 5px 4px 2px 3px rgba(202, 187, 102, 0.5); */
}

.margin-right-10 {
    margin-right: 20px;
}

.margin-left-10 {
    margin-left: 20px;
}

.fit-content {
    width: fit-content;
}

.align-to-left {
    text-align: left;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.color-dark-blue {
    color: #7b98c3;
}

.custom-germany-property-icon {
    font-size: 130%;
    margin: 1rem;
    display: inline-flex;
}

.custom-ilustration {
    max-height: 255px;
    max-width: 255px;
}

.custom-ilustration-side {
    max-height: 200px;
    max-width: 200px;
}

.custom-hw-germany-ilustration {
    max-height: 100px;
    max-width: 100px;
}

.property-text {
    color: #7b9ed0;
    text-align: center;
}

.justify-content-center {
    justify-content: center;
    font-weight: bold;
}

.justify-content-end {
    justify-content: end;
    font-weight: bold;
}

.text-align-end {
    text-align: end;
}

.text-align-left {
    text-align: left;
}

.white-background {
    background-color: white;
}

.property-ico {
    font-size: 250%;
}

.property-ico-teacher-offer {
    font-size: 180%;
}

.center-col-content {
    text-align: -webkit-center;
    text-align: -moz-center;
}

.dashed {
    border-top: 5px dashed #c0cfe4;
    align-self: center;
}

.dotted {
    border-top: 5px dotted #fbcb24;
    /* color: #fbcb24; */
}

.media-network {
    cursor: pointer;
}

.col-dotted {
    align-self: top;
    padding: 0px;
    top: 60px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-90 {
    margin-bottom: 90px;
}

.align-self-center {
    align-self: center;
}

.align-self-flex-end {
    align-self: flex-end;
}

.social-ico {
    font-size: 25px;
    margin: 10px 30px;
    cursor: pointer;
}

.no-padding {
    padding: 0;
}

.custom-btn-reasons {
    color: #656c70;
    background-color: #04a4df;
    border-color: transparent;
    font-weight: bold;
    padding: 10px 22px;
    transition: all 0.4s;
    /* -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
-moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27);
box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.27); */
}

.custom-btn-reasons-active,
.custom-btn-reasons:hover,
.custom-btn-reasons:focus,
.custom-btn-reasons:active {
    background-color: #fdcc19;
    border-color: transparent;
    box-shadow: none;
    transform: none;
    color: white;
}

.title-text-reason {
    font-weight: bold;
    margin: 15px 0;
}

.body-text-reason {
    font-weight: bold;
    color: #88949e;
    margin: 15px 0;
}

.section-text-title {
    color: #88949e;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: bold;
}

.custom-check-container {
    margin: 1rem;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    -webkit-box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
}

.bg-light-blue {
    background-color: #cdedf8 !important;

}

.custom-container {
    position: relative;
    bottom: 10rem;
    min-width: 80%;
}

.bottom-offset {
    position: relative;
    top: 6rem;
    min-width: 80%;
}

.wpp-contact {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    font-size: 15px;

}

.wpp-button {
    border: 1px solid #ecf0f5;
    border-radius: 10px;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
    /* background-color: #06d755; */
    padding: 0.7rem;

}

.custom-check-icon-container {
    border: solid 1px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 1rem;
}

.custom-check-icon-container-bg-blue {
    background-color: #04a4df;
    padding: 20px;
    border-radius: 5rem;
    max-width: 80px;
    max-height: 80px;
}

.section-text {
    color: #88949e;
    margin-bottom: 20px;
    /* Revisar este bold */
    font-weight: bold;
    /* Revisar este bold */
}

.section-text-curiosity {
    height: 50%;
}

.section-relative {
    position: relative;
    top: 95px;
}

.custom-number-container {
    background-color: white;
    border-radius: 1rem;
    padding: 0.5rem;
    border: 1px solid #7b9ed0;
}

.custom-text-company-title {
    justify-content: left;
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    font-size: 30px;
}

.custom-text-black-title {
    color: black;
    font-weight: bold;
}

.vertical-dotted-line {
    width: 1px;
    border-left: 10px dotted rgba(0, 0, 0, 0.2);
    height: 50px;
}

.curiosity-data {
    padding: 2rem;
    font-size: 20px;
    min-height: 240px !important;
}

.curiosity-data .source {
    padding-left: 10px;
    font-size: small;
}

.custom-ico-companies {
    color: #7b9ed0;
    font-size: 4rem;
    font-weight: bold;
}

.properties-container {
    margin-top: 3rem;
}

.center {
    text-align: -webkit-center;
    text-align: -moz-center;
}

.custom-card-title {
    color: #7b98c3;
    font-weight: bold;
}

.custom-card {
    border-radius: 10px;
    -webkit-box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 30px 60px -9px rgba(0, 0, 0, 0.4);
}

.spinner-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-color: rgba(22, 22, 22, 0.2); */
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
}

.modal-title {
    /* font-weight: bold; */
    font-size: 1.5rem;
}

.modal-form {
    padding: 3rem;
}

.padding-3rem {
    padding: 3rem;
}

/* ------------- Demo 1 bg-pattern ------------- */

.home-half {
    padding-bottom: 300px;
    padding-top: 220px;
}

/* ------- Demo 9 wave animation --------- */

.wave-effect {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 150px;
    overflow: hidden;
}

.shape-one {
    z-index: 15;
    opacity: 0.5;
}

.shape-two {
    z-index: 10;
    opacity: 0.75;
}

.shape-three {
    z-index: 5;
}

.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}

.waves-shape {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wave-one {
    background-size: 50% 100px;
}

.wave-anim .wave-one {
    animation: move-wave 3s;
    -webkit-animation: move-wave 3s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.wave-two {
    background-size: 50% 120px;
}

.wave-anim .wave-two {
    animation: move_wave 10s linear infinite;
}

.wave-three {
    background-size: 50% 100px;
}

.wave-anim .wave-three {
    animation: move_wave 15s linear infinite;
}


@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }

    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }

    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}



/*-------Demo 4 Rgistartion form ------*/


.home-registration-form .form-heading {
    margin-bottom: 25px;
}

.home-registration-form {
    padding: 15px 32px 32px 32px;
    border-radius: 3px;
    box-shadow: 0px px 3px 0px #000000;
    /* max-width: 100%; */
    /* max-height: 80%; */
    /* height: 500px;
    overflow: auto; */
}

.home-subtitle-width-100 {
    max-width: 100%;
}

.small-text {
    font-size: 14px;
}

.home-btn-width {
    width: 100%;
}


/*----Demo 5 Dashboard image ----*/

.home-cell-bottom {
    display: table-cell;
    vertical-align: bottom;
}

.home-padding-t-150 {
    padding-top: 150px;
}


/*---Demo 6 Subscribe -----*/

.subscribe-form input {
    padding: 15px 20px;
    width: 100%;
    font-size: 17px;
    color: #4c5667 !important;
    border: none;
    outline: none !important;
    padding-right: 180px;
    padding-left: 30px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 30px;
}

.subscribe-form button {
    position: absolute;
    text-transform: uppercase;
    top: 6px;
    right: 8px;
    outline: none !important;
    border-radius: 30px;
    font-size: 16px;
    padding: 9px 30px;
}

.subscribe-form form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
}


/*----Demo 8 Home with video --------*/

.frame-border {
    border: 9px solid rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

.custom-new-logo {
    max-height: 70px;
}



/*---Demo 9 particles ---*/

.slidero {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
}


/*======================================
     6. SERVICES
========================================*/

.services-box {
    padding: 15px 10px;
}

.services-box i {
    font-size: 32px;
    margin-bottom: 15px;
}


/*=====================================
     7. ABOUT US
======================================*/


.features-box h3 {
    margin-bottom: 25px;
    margin-top: 0;
    line-height: 35px;
    font-size: 26px;
}

.features-item-list li:before {
    content: "";
    border-radius: 50%;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 0;
    top: 14px;
}

.features-item-list li {
    position: relative;
    line-height: 24px;
    font-size: 15px;
    padding: 7px 0 7px 20px;
}

.features-img {
    max-width: 500px;
}

/*=====================================
     8. WEBSITE DESCRIPTION
======================================*/

.bg-web-desc {
    background: url("../images/img-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/*======================================
    9.  PRICING BOX
========================================*/

.pricing-box {
    padding: 50px 50px 50px 40px;
    border: 1px solid #ecf0f5;
    border-radius: 8px;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
    background-color: #ffffff;
    position: relative;
}

.pricing-box h4 {
    font-size: 15px;
}

.pricing-box h1 {
    font-size: 36px;
    padding-top: 5px;
}

.pricing-border {
    width: 100%;
    height: 1px;
    margin-top: 35px;
    background-color: #ecf0f5;
}

.price-active {
    box-shadow: 0 0rem 4rem rgba(0, 0, 0, .1) !important;
}

.plan-features p {
    padding-top: 5px;
}

.ribbon-box {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-box span {
    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #f65353;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon-box span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
}

.ribbon-box span:after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
}

/*======================================
         10. TEAM
========================================*/

.about-title h2 {
    line-height: 42px;
}

.about-title p {
    font-size: 17px;
    line-height: 30px;
    font-weight: 300;
}

.about-title {
    max-width: 800px;
}

.team-box {
    border-bottom: 1px solid #ecf0f5;
}

.team-name {
    font-size: 16px;
    padding-top: 20px;
    margin-bottom: 7px;
}

.team-designation {
    font-size: 13px;
    color: #a8aeb4;
}


/*=======================================
     11. OUR PROCESS
=========================================*/

.process-box i {
    font-size: 48px;
    line-height: 72px;
}

.plan-line:before {
    content: "";
    border: 1px dashed #e0e0e0;
    position: absolute;
    width: 67%;
    left: 65%;
    top: 50px;
}

.process-left-icon {
    content: "";
    position: absolute;
    top: 10px;
    background-color: #000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.process-left-icon-1 i,
.process-left-icon-2 i {
    font-size: 35px;
    border-radius: 50%;
    position: absolute;
    top: 83px;
    color: #fff;
    z-index: 9;
}

.process-left-icon-1 i {
    left: 63%;
}

.process-left-icon-2 i {
    left: 30%;
}


/*=======================================
       12. PEOPLE SAYS
=========================================*/

.testimonial-decs {
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
}

.testimonial-box img {
    height: 80px;
    width: 80px;
}

.testimonial-decs .testi-icon i {
    position: absolute;
    top: 0;
    right: 0;
    color: #f4f4f4;
}

.testimonial-decs p {
    line-height: 24px;
    font-style: italic;
}

/*=====================================
     13. GET STARTED
======================================*/

.bg-get-start {
    background: url("../images/img-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/*=======================================
       14. BLOG
=========================================*/

.blog-title {
    color: #212529;
    transition: all 0.5s;
}

/*======================================
    15. CONTACT
=======================================*/

.form-control {
    margin-bottom: 20px;
    box-shadow: none !important;
    height: 50px;
    padding-left: 20px;
    border: 1px solid #e0e0e0;
    /*font-size: 14px;*/
}


.form-control:focus {
    border-color: #ccc;
}

textarea.form-control {
    height: auto;
    padding-top: 13.5px;
}


.error {
    margin: 8px 0px;
    display: none;
    color: red;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    background-color: #fdf0d3;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}

.contact-loader {
    display: none;
}

#success_page {
    text-align: center;
    margin-bottom: 20px;
    background-color: #d6f3ed;
    padding: 18px;
}

#success_page h4 {
    color: #0dba26;
    font-size: 22px;
}


/*======================================
       16. FOOTER
========================================*/

.social-icon {
    border: 2px solid #40434e;
    border-radius: 50%;
    color: #6b707d !important;
    width: 36px;
    height: 36px;
    display: block;
    font-size: 15px;
    line-height: 32px;
    text-align: center;
}

.cta {
    padding: 20px 0;
}

.social-icon:hover i {
    transition: all 0.5s;
}

.footer {
    padding: 50px 0;
    background-color: #272a33;
}

.custom-dark-yellow-footer-col {
    background-color: #fbcb24;
    height: 90px;
}

.custom-dark-gray-footer-col {
    background-color: #88949e;
    height: 90px;
}

.custom-footer {
    padding: 0 0 50px 0;
    background-color: #f4f6fa;
}

.custom-footer-text {
    color: white;
    font-weight: bold;
}

.text-dark-gray {
    color: #88949e !important;
}

.translator-option {
    cursor: pointer;

}

.dropdown,
.custom-dropdown {
    background-color: transparent;
    border: none;
    color: #88949e;
    font-weight: bold;
}

.custom-dropdown:focus,
.custom-dropdown:active,
.custom-dropdown:hover,
.dropdown {
    background-color: transparent !important;
    color: #88949e !important;
    box-shadow: none;
}

.show>.btn-secondary.dropdown-toggle:focus,
.show>.btn-secondary.dropdown-toggle {
    background-color: transparent !important;
    color: #88949e !important;
    box-shadow: none;
}

.custom-new-container {
    border: 1px solid #2ab1e3;
    background-color: white;
    height: 6rem;
    width: 6rem;
    margin: auto;
    position: relative;
    right: 7rem;
    /* transform: rotate(45deg); */
    border-radius: 1rem;
}

.no-margin {
    margin: 0;
}

.footer h4 {
    color: #ffffff;
}

.footer-list li a {
    color: #98a0aa;
    padding-top: 8px;
    display: inline-block;
    font-size: 14px;
    transition: all .3s ease-in-out;
}

.footer-list li a:hover {
    color: #dce6f3;
}

.contact-title {
    font-size: 20px;
}

.contact-detail {
    padding-left: 25px;
    font-size: 12px;
}

.subscribe {
    position: relative;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 25px;
}

.subscribe input {
    height: 40px;
    background-color: #2e313a !important;
    box-shadow: none !important;
    border: none;
    padding-right: 50px;
    color: #fff !important;
}

.subscribe .submit {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    width: 50px;
    line-height: 45px;
    text-align: center;
    color: #acacac !important;
}

.footer-alt-border {
    background-color: #f5f5f5;
    height: 1px;
    width: 100%;
}

.footer-alt {
    padding: 15px 0;
    background-color: #2e313a;
}

.copy-rights {
    margin-bottom: 0;
    line-height: 36px;
}

/*=====================================
    17. EXTRA PAGES
    
====================================*/

.bg-account-pages {
    /*background: linear-gradient(to right, #363795, #005C97);*/
    background: linear-gradient(to right, #FDC34E, #fdc316);
    /* background: #FFFF33; */
    opacity: 0.9;

}

.content-register {
    position: relative;
}


.col-divider-register {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
}

.register-img img {
    width: 296px;
    max-width: 100%;
}

.account-home-btn {
    position: absolute;
    top: 18px;
    right: 28px;
    z-index: 1;
}

.account-pages-logo {
    letter-spacing: 2px;
}

.account-card form .form-control {
    height: 45px;
}

.account-card form label {
    font-size: 15px;
}

.account-card .custom-control-input:checked~.custom-control-label::before {
    background: #fb3e3e;
    border-color: #fb3e3e;
}

.account-card .alert {
    font-size: 14px;
}

#root {
    height: 100%;
}


/*=====================================
    18. RESPONSIVE
====================================*/

@media (min-width: 1024px) {
    .section-register {
        width: 80%;
        max-width: none;
    }
}

@media (max-width: 1024px) {

    .navbar-custom .navbar-nav {
        margin-left: 0;
    }

    .home-half .slidero {
        top: 80%;
        transform: translateY(-50%);
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
    }

    .home-half {
        padding-bottom: 300px;
        padding-top: 220px;
    }

    .home-padding-t-150 {
        padding-top: 110px;
    }

    /* .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
        color: #fff !important;
    } */

    .navbar-white {
        margin-top: 0px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
        color: #fff !important;
    }

    .navbar-white .navbar-toggler {
        color: #393f4f !important;
    }

    .navbar-custom .navbar-nav li a {
        transition: all 0.4s;
        margin: 0px;
    }

    .navbar-custom>.container-fluid {
        width: 100%;
    }

    .navbar-custom .navbar-nav li.active a {
        color: #fff !important;
        border-color: transparent;
    }

    .navbar-custom>.container {
        width: 90%;
    }

    /* .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #fff !important;
    } */

    .login-btn {
        padding-left: 10px;
    }

    .navbar-custom .navbar-brand {
        line-height: 24px;
    }

    .home-title {
        text-transform: uppercase;
        justify-content: left;
        font-size: 32px;
        line-height: 48px;
    }

    .vertical-content {
        display: inherit;
    }

    .team-box {
        margin-top: 45px;
    }

    .features-img {
        margin-top: 30px;
    }

    .pricing-box {
        margin-top: 30px;
    }
}

/*==================================
STYLE SWITHER - Demo purpose only
==================================*/

#style-switcher div h3 {
    font-size: 16px;
    margin: 8px 3px 12px;
}

#style-switcher {
    background: none repeat scroll 0 0 #ffff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
    left: -189px;
    position: fixed;
    top: 17%;
    width: 189px;
    z-index: 9999;
    padding: 10px 5px;
}

#style-switcher div {
    padding: 5px 10px;
}

#style-switcher .bottom {
    background: none repeat scroll 0 0 #FFFFFF;
    color: #252525;
    padding: 0;
}

#style-switcher .bottom a.settings {
    background: none repeat scroll 0 0 #2b2424;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
    display: block;
    height: 41px;
    position: absolute;
    right: -40px;
    top: 0;
    width: 40px;
    padding: 3px;
}

#style-switcher .bottom a {
    text-decoration: none;
}

#style-switcher .bottom a.settings i {
    margin-left: 5px;
    color: #ffffff;
    font-size: 24px;
    position: absolute;
}

ul.pattern {
    list-style: none outside none;
    margin: 0 0 0px;
    overflow: hidden;
    padding: 0;
    border-radius: 0px;
}

ul.pattern li {
    float: left;
    margin: 2px;
}

ul.pattern li a {
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 30% 70% 64% 36% / 30% 42% 58% 70%;
    margin: 5px;
}

ul.pattern .color1 {
    background-color: #1bbc9d;
}

ul.pattern .color2 {
    background-color: #ee6352;
}

ul.pattern .color3 {
    background-color: #59CD90;
}

ul.pattern .color4 {
    background-color: #f85f89;
}

ul.pattern .color5 {
    background-color: #3fa7d6;
}

ul.pattern .color6 {
    background-color: #704fff;
}

ul.pattern .color7 {
    background-color: #f28123;
}

ul.pattern .color8 {
    background-color: #f6cb42;
}

.carousel-centered {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.slideshow {
    position: relative;
    overflow: hidden;
    height: 30rem;
}

.images-all-can-learn {
    background: url("../images/infinite/circulos-01.png");
    position: absolute;
    left: 0;
    top: 0;
    height: 89%;
    width: 300%;
    animation: slideshow 30s linear infinite;
}

.images-all-can-learn-eng {
    background: url("../images/infinite/circulos-01-eng.png");
    position: absolute;
    left: 0;
    top: 0;
    height: 89%;
    width: 300%;
    animation: slideshow 30s linear infinite;
}

.slideshow-teachers {
    position: relative;
    overflow: hidden;
    height: 50rem;
}

.images-teachers {
    background: url("../images/infinite/circulos-02.png");
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300%;
    animation: slideshow 30s linear infinite;
}

#carousel {
    width: 100%;
    /* height: 150px; */
    /* background-color: #ff0000; */

    overflow-x: auto;
    white-space: nowrap;
}

#carousel .slide {
    display: inline-block;
}

@keyframes slideshow {
    0% {
        left: 0;
    }

    100% {
        left: -95%;
    }
}

.carousel-control-prev {
    /* background-color: #97989d;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    top: 50%;
    left: 150px; */
    cursor: pointer;
    z-index: 0;
}

.carousel-control-next {
    /* background-color: #97989d;
height: 100px;
width: 100px;
border-radius: 100px;
top: 50%;
right: 150px; */
    cursor: pointer;
    z-index: 0;
}

/* .carousel-inner {
    min-height: 490px;
} */


.carousel-indicators {
    bottom: -50px
}

.carousel-indicators li {
    background-color: grey;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.image-class {
    max-height: 20rem;
    max-width: 30rem;
}

.custom-new-title {
    color: #9da4ab;
    font-weight: bold;
}

.custom-new-text {
    color: #9da4ab;
    font-weight: bold;
}

.vertical-margin-50 {
    margin: 50px 0;
}

.testimonial-image {
    max-width: 200px;
    max-width: 200px;
}

.testimonial-box {
    /* max-width: 75%; */
    min-height: 400px;
}

.testimonial-text {
    max-width: 60%;
    font-style: italic;
}

/* .custom-modal {
    width: 80%;
} */


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

@media only screen and (max-width: 325px) {

    .bg-home {
        background: url("../images/backgrounds/tingo-home-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-teachers-home {
        background: url("../images/backgrounds/tingo-teachers-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-germany-home {
        background: url("../images/backgrounds/tingo-germany-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-news-home {
        background: url("../images/backgrounds/tingo-news-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-companies-home {
        background: url("../images/backgrounds/tingo-companies-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .custom-hw-germany-ilustration {
        max-width: 75px;
        max-height: 75px;
    }

    .custom-benefit-ilustration {
        max-width: 75px;
        max-height: 75px;
    }

    .teacher-circle-images {
        max-width: 220px !important;
    }

    .invisible-on-mobile-xs {
        display: none;
    }

    .custom-ilustration {
        max-width: 100px !important;
        max-height: 100px !important;
    }
}

@media only screen and (max-width: 767px) {

    .bg-home {
        background: url("../images/backgrounds/tingo-home-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-teachers-home {
        background: url("../images/backgrounds/tingo-teachers-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-germany-home {
        background: url("../images/backgrounds/tingo-germany-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-news-home {
        background: url("../images/backgrounds/tingo-news-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-companies-home {
        background: url("../images/backgrounds/tingo-companies-bg-mobile.png") !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .invisible-on-mobile-sm {
        display: none;
    }

    .home-half {
        padding-top: 130px;
        padding-bottom: 300px;
    }

    .custom-benefit-ilustration {
        max-width: 75px !important;
        max-height: 75px !important;
    }

    .custom-hw-germany-ilustration {
        max-width: 75px !important;
        max-height: 75px !important;
    }

    .custom-ilustration-number {
        max-width: 150px !important;
        max-height: 150px !important;
    }

    .custom-ilustration {
        max-width: 150px !important;
        max-height: 150px !important;
    }

}

/* Small devices (tablets, 768px and up) */
@media only screen and (max-width: 768px) {



    .custom-check-icon-container-bg-blue {
        background-color: #04a4df;
        padding: 0px;
        border-radius: 5rem;
        max-width: 80px;
        max-height: 80px;
    }

    .invisible-on-mobile {
        display: none;
    }

    .teacher-circle-images {
        max-width: 350px;
    }

    /* .property-ico {
    font-size: 30px;
} */

    .testimonial-box {
        min-height: 560px;
    }

    .property-ico-teacher-offer {
        font-size: 30px;
    }

    .custom-ilustration {
        max-width: 150px;
        max-height: 150px;
    }

    .custom-benefit-ilustration {
        max-width: 210px;
        max-height: 210px;
    }

    .custom-ilustration-number {
        max-width: 200px;
        max-height: 200px;
    }

    .custom-hw-germany-ilustration {
        max-width: 80px;
        /* max-height: 200px; */
    }

    .bg-overlay-mobile {
        background: #deeaf6;
        /* fallback for old browsers */
        /* background: linear-gradient(to right, #363795, #005C97); */
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        opacity: 0.80;
        position: absolute;
        height: 100%;
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
    }

    .margin-left-10 {
        margin: 10px !important;
    }

    .margin-right-10 {
        margin: 10px !important;
    }

    .custom-container {
        position: relative;
        bottom: 6rem;
        min-width: 80%;
    }

    .mobile-full-center {
        text-align: -webkit-center;
        text-align: -moz-center;
    }

    .bg-white-gray {
        background: #9da4ab !important;
    }

    .bg-gray-white {
        background: white !important;
    }

    .curiosity-data {
        min-height: 425px !important;
    }

    .custom-new-container {
        right: 0;
    }

}


/* Medium devices (desktops, 992px and up) */
@media only screen and (max-width: 1023px) {

    .logo {
        display: none;
    }

    .bg-home {
        background: url("../images/backgrounds/tingo-home-bg-tablet.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .bg-teachers-home {
        background: url("../images/backgrounds/tingo-teachers-bg-tablet.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .bg-germany-home {
        background: url("../images/backgrounds/tingo-germany-bg-tablet.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .bg-news-home {
        background: url("../images/backgrounds/tingo-news-bg-tablet.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .bg-companies-home {
        background: url("../images/backgrounds/tingo-companies-bg-tablet.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .width-fit-content {
        width: auto;
    }

    .dotted {
        display: none;
    }

    .home-half {
        padding-top: 130px;
        padding-bottom: 300px;
    }

}

@media only screen and (min-width: 1024px) {

    .home-nav {
        display: none;
    }

    .border-dotted-yellow {
        border: 5px dashed #fbcb24;
        margin: 0 10%;
    }

    .gray-screen {
        display: block;
        width: 27vw;
        position: absolute;
        height: 50%;
        /* top: 0; */
        /* right: 0; */
        background-color: rgba(157, 164, 171, 1);
        z-index: -1;
    }

    .custom-new-container {
        right: 7rem;
    }

    .testimonial-boxtext {
        min-height: 170px;
    }

    .teacher-circle-images {
        max-width: 900px;
    }

    .custom-container {
        position: relative;
        bottom: 4rem;
        min-width: 80%;
    }

    /* .width-fit-content {
    width: fit-content;
} */

    .carousel-control-prev {
        background-color: #97989d;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        top: 50%;
        left: 150px;
        z-index: 0;
    }

    .carousel-control-next {
        background-color: #97989d;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        top: 50%;
        right: 150px;
        z-index: 0;
    }

    .custom-container-min-width-80 {
        min-width: 80%;
    }

    .color-blue {
        display: block;
        background-color: rgb(27, 175, 228);
        height: 2px;
    }

    .custom-benefit-ilustration {
        max-width: 100px;
        max-height: 100px;
    }

    .custom-ilustration-number {
        max-width: 100px;
        max-height: 100px;
    }

    .custom-ilustration {
        max-width: 100px;
        max-height: 100px;
    }

    .custom-ilustration-side {
        max-width: 100px;
        max-height: 100px;
    }

    /* .carousel-item-next {
    text-align: center;
    align-self: center;
}

.carousel-item-right {
    text-align: center;
    align-self: center;
}

.carousel-item-left {
    text-align: center;
    align-self: center;
} */
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .width-fit-content {
        width: fit-content;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and (max-width: 1399px) {
    .width-fit-content {
        width: auto;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {

    .custom-new-container {
        right: 7.5rem;
    }

    .custom-benefit-ilustration {
        max-width: 150px;
        max-height: 150px;
    }

    .custom-ilustration {
        max-width: 150px;
        max-height: 150px;
    }

    .custom-ilustration-number {
        max-width: 100px;
        max-height: 100px;
    }

    .width-fit-content {
        width: auto;
    }
}

/* Large devices (large desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
    .width-fit-content {
        width: auto;
    }
}